import { render, staticRenderFns } from "./clientes.vue?vue&type=template&id=3450a7f5&scoped=true"
import script from "./clientes.vue?vue&type=script&lang=js"
export * from "./clientes.vue?vue&type=script&lang=js"
import style0 from "./clientes.vue?vue&type=style&index=0&id=3450a7f5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3450a7f5",
  null
  
)

export default component.exports